html {
    font-family: "Noto Sans KR", sans-serif;
}

body {
    margin: 0;
}

.ant-descriptions {
    margin-bottom: 8px;
}

.ant-card {
    margin-bottom: 8px;
}
